<script>
export default {
  props: {
    value: {
      type: Array
    },
    icon: {
      type: String,
      default: 'mdi-brain'
    },
    themeColor: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selectedSkills: this.value,
      search: null,
      items: []
    }
  },
  methods: {
    handleRemoveSkill (item) {
      this.selectedSkills = this.selectedSkills.filter((skill) => skill !== item)
    },
    removeEmptyStrings (value) {
      this.selectedSkills = value.filter(el => el.trim() !== '')
      const lastSkillIndex = this.selectedSkills.length - 1
      this.selectedSkills[lastSkillIndex] = this.selectedSkills[lastSkillIndex].slice(0, 255)
    }
  },
  watch: {
    value (val) {
      this.selectedSkills = val
    },
    search (input) {
      if (!input || input.length < 2) return
      this.debounceEvent(async () => {
        const { data } = await this.$store.dispatch('attemptGetSkills', input)
        if (data.length) {
          this.items = data.map(skill => (skill.name))
        }
      }, 300)
    },
    selectedSkills () {
      this.$emit('input', this.selectedSkills)
    }
  }
}
</script>
<template>
  <v-row justify="center" align="center">
    <v-col cols="12" sm="8">
      <v-combobox
        class="combobox-skills"
        v-model="selectedSkills"
        :items="items"
        :search-input.sync="search"
        prepend-inner-icon="mdi-brain"
        clearable
        hide-selected
        multiple
        outlined
        small-chips
        @change="removeEmptyStrings"
        dense
      >
        <template v-slot:selection="{ attrs, item, select, selected }">
          <v-chip
            class="chip-input ma-1"
            v-bind="attrs"
            :input-value="selected"
            close
            :color="themeColor ? themeColor : '#1971F31A'"
            :text-color="themeColor? '#fff' : '#1971F3'"
            @click="select"
            @click:close="handleRemoveSkill(item)"
          >
            {{ item }}
          </v-chip>
        </template>
        <template v-slot:item="{ item }">
          {{ item }}
        </template>
      </v-combobox>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.combobox-skills {
  .v-input__prepend-inner{
    margin-top: 9px !important;
  }
}
</style>
